// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DynamicComponent from './components/DynamicComponent';
import HomePage from './components/HomePage';
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/:uniqueKey" element={<DynamicComponent />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
