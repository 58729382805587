// DynamicComponent.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaHome, FaShare } from 'react-icons/fa';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ShareIcon from '@mui/icons-material/Share';
import HomeIcon from '@mui/icons-material/Home';

import './DynamicComponent.css'; // You can create a CSS file for styling

const DynamicComponent = () => {
    const { uniqueKey } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [Component, setComponent] = useState(null);
    const [shareText, setShareText] = useState("");

    useEffect(() => {
        const fetchComponent = async () => {
            try {
                // Dynamically import the component based on the unique key
                const componentModule = await import(`/${uniqueKey}/${toPascalCase(uniqueKey)}`);
                setComponent(() => componentModule.default); // Update state using a function to avoid warning
                console.log('sharetext--',componentModule.shareText)
                setShareText(componentModule.shareText || "")
                setLoading(false);
            } catch (error) {
                console.error('Error loading component:', error);
                // Handle error (e.g., render a fallback component)
                setLoading(false);
            }
        };

        fetchComponent();
    }, [uniqueKey]);

    // Avoid calling hooks inside the useEffect
    // Instead, conditionally render based on the loading state
    if (isLoading) {
        return <div>Loading...</div>; // Placeholder element while component is loading
    }

    if (!Component) {
        return <div>Error loading component</div>; // Render an error message if component failed to load
    }

    const handleShare = async () => {
        console.log('sharettt  ',shareText)
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'badhaido.in',
                    text: shareText,
                    url: window.location.href
                });
                console.log('Shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
        }
    };

    return (
        <div className="dynamic-component-container">
            <Component />
            
            {/* Create Button */}
            {/* <Link to="/create" className="create-button">Create</Link> */}
            
            {/* Floating Share Button */}
            <div className="floating-share-button">
                {/* <button onClick={handleShare} className='share-button'><FaShare /></button> */}
                {/* <Link to="/" className="home-button"><FaHome /></Link> */}
                {/* <a href='/' className="home-button" ><FaHome /></a> */}
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Fab color="primary" aria-label="share">
                    <ShareIcon onClick={handleShare} />
                </Fab>
                <Fab color="primary" aria-label="home">
                    <a href='/'> <HomeIcon /> </a>
                </Fab>
                
            </Box>
            </div>
        </div>
    );
};

// Utility function to convert kebab-case to PascalCase
const toPascalCase = (str) => {
    return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
};

export default DynamicComponent;
