import React, { useEffect } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import hppyBirthdayImage from './hppy-birthday.jpg';
import shreeRamImage from './shree-ram.png';
import ramadanImage from './ramadan.png';
import eidImage from './eid-moon.png';
import morningImage from './smiling-sun.png';
import navratriImage from './navratri.png';
import maaDurgaImage from './Maa-Durga-Face.png';
import moonImage from './good_night/moon-bright.png';


// Define the card data
const cardData = [
  { id: 1, title: 'Navratri', imageUrl: navratriImage, uniquekey: 'shubh_navratri' },
  { id: 12, title: 'Maa Durga', imageUrl: maaDurgaImage, uniquekey: 'happy_navratri2' },
  { id: 2, title: 'Ramnavami', imageUrl: shreeRamImage, uniquekey: 'happy_ramnavami' },
  { id: 3, title: 'Birthday', imageUrl: hppyBirthdayImage, uniquekey: 'happy_birthday' },
  { id: 4, title: 'Eid', imageUrl: eidImage, uniquekey: 'eid_mubarak' },
  { id: 5, title: 'Ramadan', imageUrl: ramadanImage, uniquekey: 'happy_ramadan' },
  { id: 6, title: 'Good Morning', imageUrl: morningImage, uniquekey: 'good_morning' },
  { id: 6, title: 'Good Night', imageUrl: moonImage, uniquekey: 'good_night' },
  
];

// Card component
const Card = ({ id, title, imageUrl, uniquekey }) => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${uniquekey}`);
  };

  return (
    <div className="col-sm-3 col-3 mb-4">
      <div className="card" onClick={handleClick}>
        <img src={imageUrl} alt={title} className="card-img-top img-responsive" />
        <div className="card-body">
          <h6 className="card-title">{title}</h6>
        </div>
      </div>
    </div>
  );
};

// Homepage component
const Homepage = () => {
  useEffect(() => {
    // Add glitter effect elements to the body
    const body = document.querySelector('body');
    for (let i = 0; i < 100; i++) {
      const glitter = document.createElement('div');
      glitter.classList.add('glitter');
      glitter.style.left = Math.random() * window.innerWidth + 'px';
      glitter.style.top = Math.random() * window.innerHeight + 'px';
      body.appendChild(glitter);
    }
  }, []);

  return (
    <div className="homepage">
      <h1 className="website-name">BadhaiDo.in</h1>
      <p className="website-description">अपने दोस्तों और रिश्तेदारों को सुन्दर बधाइयाँ भेजें !</p>
      <div className="container-fluid card-container">
        <div className="row">
          {cardData.map(card => (
            <Card key={card.id} {...card} />
          ))}
        </div>
      </div>
        
    </div>
  );
};

export default Homepage;
